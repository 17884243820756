<template>
  <div class="main-container">
    <Head :login="login" @refreshLoginData="refreshLoginData" />
    <div class="main-content">
      <Side class="content-side" />
      <div class="logout-content" @click="handleLogout"> 退出 </div>
    </div>
  </div>
</template>

<script>
import Head from '../Layout/Main/Head'
import Side from '../Layout/Main/Side'

import { postLogout } from '@/api/client/member'
import resStatus from '@/constants/resStatus'
import { goPage } from '@/utils/route'
import { memberLogout } from '@/utils/member'
import { isMobile } from '@/utils/device'

export default {
  components: {
    Head,
    Side,
  },
  props: {
    login: {
      type: Object,
      required: true,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return isMobile()
    },
  },
  methods: {
    refreshLoginData() {
      this.$emit('refreshLoginData')
    },
    updateLogin(obj) {
      this.$emit('updateLogin', obj)
    },
    handleLogout() {
      postLogout()
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              if (
                this.$route.matched[0].name === 'Member' ||
                this.$route.matched[1].name === 'GodPlay'
              ) {
                goPage({ name: 'Home' })
              }
              this.handleLogoutSuccess()
              break
            default:
              console.log('logout error: ', res)
          }
        })
        .catch((err) => {
          console.error('logout error: ', err)
        })
    },
    handleLogoutSuccess() {
      this.updateLogin({
        data: null,
        isLogin: false,
      })
      memberLogout()
    },
  },
  watch: {
    login(curData) {
      if (!curData.isLogin && !curData.data) {
        goPage({ name: 'Home' })
      }
      if (!curData.formType) {
        document.querySelector('#app').style.height = 'unset'
        document.querySelector('#app').style.overflow = 'unset'
      } else {
        document.querySelector('#app').style.height = '100vh'
        document.querySelector('#app').style.overflow = 'hidden'
      }
    },
    isMobile: {
      handler: function () {
        if (!this.isMobile) {
          this.$router.replace({ name: 'MemberInfo' })
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
  }
  .logout-content {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    padding: 15px 0;
    text-align: center;
    margin: 20px 0 30px;
    color: #4f4f4f;
    background-color: #fff;
  }
}
@media (max-width: 575.98px) {
  .main-container {
    width: 100%;
    .main-content {
      flex-direction: column;
      background-color: #f3f9ff;
      flex-wrap: unset;
      .content-main {
        width: 100%;
        height: 45.555vh;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container {
    width: 100%;
    .main-content {
      .content-main {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
    .main-content {
      // gap: 20px;
      padding: 30px 35px 45px;
      .content-side {
        width: 24.167vw;
        margin-right: 20px;
      }
      .content-main {
        // width: 788px;
        width: calc(100% - 24.167vw - 20px);
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container {
    width: 100%;
    padding: 10px 0 30px;
    .main-content {
      // gap: 20px;
      padding: 30px 40px 45px;
      .content-side {
        width: 24.167vw;
        margin-right: 20px;
      }
      .content-main {
        // width: 788px;
        width: calc(100% - 24.167vw - 20px);
        margin-left: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .main-container {
    width: 1200px;
    padding: 10px 0 30px;
    .main-content {
      // gap: 20px;
      padding: 30px 51px 50px;
      .content-side {
        width: 290px;
        margin-right: 20px;
      }
      .content-main {
        width: 788px;
      }
    }
  }
}
</style>
